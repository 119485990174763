"use client";

import { NavigationSectionProps } from "@/website-editor/blocks/navigation-section/index";
import { useOrganization } from "@/student/context/OrganizationContext";
import { cn, hsv2hex } from "@/lib/utils";
import { textColorVariants, Theme } from "@/website-editor/custom-fields/theme";
import { paddingVariants } from "@/website-editor/custom-fields/padding";
import Image from "next/image";
import { roundedVariants } from "@/website-editor/custom-fields/rounded";
import PiLogo from "@/components/PiLogo";
import { ButtonsComponent } from "@/website-editor/blocks/button";
import React, { useState } from "react";
import useLogout from "@/auth/hooks/useLogout";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@/components/ui/navigation-menu";
import { Button } from "@/components/ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import BurgerIcon from "@/components/BurgerIcon";
import PiLink from "@/components/PiLink";
import { useViewer } from "@/student/context/OrganizationContext";
import {
  AnimatePresence,
  motion,
  useMotionValueEvent,
  useScroll,
} from "framer-motion";

const NavigationSection: React.FC<
  NavigationSectionProps & {
    isEditing?: boolean;
  }
> = ({
  sectionId,
  logo,
  logoRoundness,
  logoUrl,
  navigationBarItems,
  theme,
  buttons,
  paddingTop,
  paddingBottom,
  marginLeft,
  marginRight,
  harmony = [],
  isFloating = true,
  isEditing,
}) => {
  const organization = useOrganization();
  const viewer = useViewer();

  const isLoggedIn = Boolean(organization?.viewerStudent);

  const filteredNavigationBarItems =
    navigationBarItems?.filter((item) => {
      if (item.href === undefined) return false;

      if (item.show === "logged-in" && !isLoggedIn) {
        return false;
      }

      if (item.show === "logged-out" && isLoggedIn) {
        return false;
      }

      return true;
    }) ?? [];

  const filteredButtons =
    buttons?.filter((button) => {
      if (button.url === undefined) return false;

      if (button.show === "logged-in" && !isLoggedIn) {
        return false;
      }

      if (button.show === "logged-out" && isLoggedIn) {
        return false;
      }

      return true;
    }) ?? [];

  const logoElem = (
    <section>
      {logo?.url ? (
        <Image
          src={logo?.url ?? ""}
          alt={""}
          width={512}
          height={512}
          unoptimized={!logo?.url.includes(".pi.education/")}
          className={cn(
            "absolute left-1/2 top-1/2 h-12 w-auto -translate-x-1/2 -translate-y-1/2 text-primary lg:static lg:mr-8 lg:h-16 lg:translate-x-0 lg:translate-y-0",
            roundedVariants({ rounded: logoRoundness }),
          )}
        />
      ) : organization.image ? (
        <Image
          src={organization.image}
          alt={organization.name}
          width={512}
          height={512}
          unoptimized={!organization.image.includes(".pi.education/")}
          className={cn(
            "absolute left-1/2 top-1/2 h-12 w-auto -translate-x-1/2 -translate-y-1/2 text-primary lg:static lg:mr-8 lg:h-16 lg:translate-x-0 lg:translate-y-0",
            roundedVariants({ rounded: logoRoundness }),
          )}
        />
      ) : (
        <PiLogo className="absolute left-1/2 top-1/2 size-8 -translate-x-1/2 -translate-y-1/2 text-primary lg:static lg:mr-8 lg:size-12 lg:translate-x-0 lg:translate-y-0" />
      )}
    </section>
  );

  const { scrollY } = useScroll();

  const [visible, setVisible] = useState(true);

  useMotionValueEvent(scrollY, "change", (current) => {
    // Check if current is not undefined and is a number
    const direction = (current ?? 0) - scrollY.getPrevious()!;
    if (current < 320) {
      setVisible(true);
    } else {
      if (direction < 0) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  });

  return (
    <>
      <AnimatePresence mode="wait">
        <motion.header
          id={sectionId || undefined}
          className={cn(
            isEditing || !isFloating ? "relative" : "fixed z-50 inset-x-0",
            paddingVariants({ side: "top", size: paddingTop }),
            paddingVariants({ side: "bottom", size: paddingBottom }),
            {
              dark: theme === "dark",
              "bg-background": harmony.length === 0,
            },
          )}
          initial={{
            opacity: 1,
            y: -100,
          }}
          animate={{
            y: isEditing || !isFloating || visible ? 0 : -100,
            opacity: isEditing || !isFloating || visible ? 1 : 0,
          }}
          transition={{
            duration: 0.2,
          }}
          style={
            harmony[0]
              ? {
                  backgroundColor: hsv2hex(
                    harmony[0].hue,
                    harmony[0].saturation,
                    harmony[0].value,
                  ),
                }
              : undefined
          }
        >
          <nav
            className={cn(
              "mx-auto flex min-h-12 max-w-7xl items-center",
              paddingVariants({ side: "left", size: marginLeft }),
              paddingVariants({ side: "right", size: marginRight }),
            )}
          >
            {logoUrl ? (
              <PiLink href={logoUrl} className="shrink-0">
                {logoElem}
              </PiLink>
            ) : (
              logoElem
            )}
            <MobileHamburgerMenu
              harmony={harmony}
              viewer={organization.viewerStudent ? viewer : null}
              theme={theme}
            >
              {filteredNavigationBarItems.map((item, index) => {
                if (item.isMenu && item.subItems) {
                  return (
                    <DropdownMenuGroup key={index + (item.href ?? "#")}>
                      <DropdownMenuLabel>{item.title}</DropdownMenuLabel>
                      {item.subItems.map((subItem, subIndex) => (
                        <DropdownMenuItem
                          className="hover:bg-gray-500/50 focus:bg-gray-500/50"
                          key={subIndex + (subItem.href ?? "#")}
                          asChild
                        >
                          <PiLink href={subItem.href ?? "#"}>
                            {subItem.title}
                          </PiLink>
                        </DropdownMenuItem>
                      ))}
                      <DropdownMenuSeparator />
                    </DropdownMenuGroup>
                  );
                }

                return (
                  <DropdownMenuItem
                    key={index + (item.href ?? "#")}
                    className="hover:bg-gray-500/50 focus:bg-gray-500/50"
                    asChild
                  >
                    <PiLink href={item.href ?? "#"}>{item.title}</PiLink>
                  </DropdownMenuItem>
                );
              })}

              {filteredButtons.length > 0 ? (
                <>
                  {filteredNavigationBarItems.length > 0 ? (
                    <DropdownMenuSeparator />
                  ) : null}
                  <DropdownMenuGroup>
                    {filteredButtons.map((item, index) => (
                      <DropdownMenuItem
                        className="hover:bg-gray-500/50 focus:bg-gray-500/50"
                        key={index + item.url}
                        asChild
                      >
                        <PiLink href={item.url}>{item.label}</PiLink>
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuGroup>
                </>
              ) : null}
            </MobileHamburgerMenu>
            <div className="hidden flex-1 items-center lg:flex lg:gap-x-8">
              <NavigationMenu>
                <NavigationMenuList>
                  {filteredNavigationBarItems.map((item, index) => {
                    if (item.isMenu && item.subItems) {
                      return (
                        <NavigationMenuItem key={index + (item.href ?? "#")}>
                          <NavigationMenuTrigger
                            className={cn(
                              "group inline-flex h-9 w-max items-center justify-center rounded-md px-4 py-2 text-sm font-medium transition-colors hover:bg-gray-500/50 focus:bg-gray-500/50 focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-gray-500/50 data-[state=open]:bg-gray-500/50",
                              "text-base font-semibold leading-2 break-words whitespace-pre-line",
                              textColorVariants({ theme }),
                            )}
                          >
                            {item.title}
                          </NavigationMenuTrigger>
                          <NavigationMenuContent
                            className="rounded bg-background text-center ring ring-gray-500/50"
                            style={
                              harmony[0]
                                ? {
                                    backgroundColor: hsv2hex(
                                      harmony[0].hue,
                                      harmony[0].saturation,
                                      harmony[0].value,
                                    ),
                                  }
                                : undefined
                            }
                          >
                            <NavigationMenuList className="flex-col">
                              {item.subItems.map((subItem, subIndex) => (
                                <PiLink
                                  key={subIndex + (item.href ?? "#")}
                                  href={subItem.href ?? "#"}
                                  legacyBehavior
                                  passHref
                                >
                                  <NavigationMenuLink
                                    className={cn(
                                      "group inline-flex w-full items-center justify-center rounded-md px-4 py-2 text-sm font-medium transition-colors hover:bg-gray-500/50 focus:bg-gray-500/50 focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-gray-500/50 data-[state=open]:bg-gray-500/50",
                                      "text-base font-semibold leading-2 break-words whitespace-pre-line",
                                      textColorVariants({ theme }),
                                    )}
                                  >
                                    {subItem.title}
                                  </NavigationMenuLink>
                                </PiLink>
                              ))}
                            </NavigationMenuList>
                          </NavigationMenuContent>
                        </NavigationMenuItem>
                      );
                    }

                    return (
                      <NavigationMenuItem key={index + (item.href ?? "#")}>
                        <PiLink href={item.href ?? "#"} legacyBehavior passHref>
                          <NavigationMenuLink
                            className={cn(
                              "group inline-flex h-9 w-max items-center justify-center rounded-md px-4 py-2 text-sm font-medium transition-colors hover:bg-gray-500/50 focus:bg-gray-500/50 focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-gray-500/50 data-[state=open]:bg-gray-500/50",
                              "text-base font-semibold leading-2 break-words whitespace-pre-line",
                              textColorVariants({ theme }),
                            )}
                          >
                            {item.title}
                          </NavigationMenuLink>
                        </PiLink>
                      </NavigationMenuItem>
                    );
                  })}
                </NavigationMenuList>
              </NavigationMenu>
              <ButtonsComponent
                className="flex-1 justify-end"
                buttons={filteredButtons}
                on={theme}
              >
                {organization?.viewerStudent && viewer ? (
                  <DesktopNavigationSectionViewer viewer={viewer} />
                ) : null}
              </ButtonsComponent>
            </div>
          </nav>
        </motion.header>
      </AnimatePresence>
      {isEditing || !isFloating ? null : (
        <div
          className={cn(
            paddingVariants({ side: "top", size: paddingTop }),
            paddingVariants({ side: "bottom", size: paddingBottom }),
          )}
        >
          <div className="min-h-12"></div>
        </div>
      )}
    </>
  );
};

const MobileHamburgerMenu: React.FC<
  React.PropsWithChildren<{
    viewer: ReturnType<typeof useViewer>;
    harmony: NavigationSectionProps["harmony"];
    theme: Theme;
  }>
> = ({ viewer, harmony, theme, children }) => {
  const fullName = [viewer?.givenName, viewer?.lastName]
    .filter(Boolean)
    .join(" ");

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const [logout, isLogoutInFlight] = useLogout();

  return (
    <DropdownMenu onOpenChange={setIsMenuOpen} open={isMenuOpen}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          on={theme}
          className={"size-8 lg:hidden"}
        >
          <BurgerIcon toggled={isMenuOpen} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className={cn("w-screen ring ring-gray-500/50", {
          dark: theme === "dark",
          "bg-background": harmony.length === 0,
        })}
        onCloseAutoFocus={(e) => {
          // need to prevent auto focus since it messes up #links
          e.preventDefault();
        }}
        align="start"
        style={
          harmony[0]
            ? {
                backgroundColor: hsv2hex(
                  harmony[0].hue,
                  harmony[0].saturation,
                  harmony[0].value,
                ),
              }
            : undefined
        }
      >
        {viewer ? (
          <>
            <DropdownMenuLabel className="font-normal">
              <div className="flex flex-col space-y-1">
                <p className="text-sm font-medium leading-none">{fullName}</p>
                <p className="text-xs leading-none text-muted-foreground">
                  {viewer.email}
                </p>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
          </>
        ) : null}
        {children}
        {viewer ? (
          <>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="hover:bg-gray-500/50 focus:bg-gray-500/50"
              onClick={() => {
                logout();
              }}
            >
              Oturumu Kapat
              <DropdownMenuShortcut className="hidden">
                ⇧⌘Q
              </DropdownMenuShortcut>
            </DropdownMenuItem>
          </>
        ) : null}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
const DesktopNavigationSectionViewer: React.FC<{
  viewer: NonNullable<ReturnType<typeof useViewer>>;
}> = ({ viewer }) => {
  const fullName = [viewer.givenName, viewer.lastName]
    .filter(Boolean)
    .join(" ");

  const initials = [viewer.givenName, viewer.lastName]
    .filter(Boolean)
    .map((name) => name?.[0])
    .join("");

  const organization = useOrganization();

  const [logout, isLogoutInFlight] = useLogout();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="relative hidden size-8 rounded-full lg:block"
          size="icon"
        >
          <Avatar className="size-8">
            <AvatarImage src={viewer.image ?? undefined} alt={fullName} />
            <AvatarFallback>{initials}</AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-56"
        align="end"
        onCloseAutoFocus={(e) => {
          // need to prevent auto focus since it messes up #links
          e.preventDefault();
        }}
      >
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">{fullName}</p>
            <p className="text-xs leading-none text-muted-foreground">
              {viewer.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />

        <DropdownMenuItem
          className="hover:bg-gray-500/50 focus:bg-gray-500/50"
          asChild
        >
          <PiLink href={`/${organization.slug}/derslerim`}>Derslerim</PiLink>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="hover:bg-gray-500/50 focus:bg-gray-500/50"
          asChild
        >
          <PiLink href={`/${organization.slug}/birebir`}>
            Birebir Görüşmeler
          </PiLink>
        </DropdownMenuItem>
        <DropdownMenuSeparator />

        <DropdownMenuItem
          className="hover:bg-gray-500/50 focus:bg-gray-500/50"
          onClick={() => {
            logout();
          }}
        >
          Oturumu Kapat
          <DropdownMenuShortcut className="hidden">⇧⌘Q</DropdownMenuShortcut>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default NavigationSection;
